import { createSlice } from "@reduxjs/toolkit";
const saleCartSlice = createSlice({
    name: "saleCart",
    initialState: [],
    reducers: {
      addToSaleCartReducer: (state, action) => {
        state.push(action.payload);
      },
      removeSaleProductFromState: (state, action) => {
        const arrayIndex = action.payload - 1;
        if (arrayIndex >= 0 && arrayIndex < state.length) {
          const updatedState = [
            ...state.slice(0, arrayIndex),
            ...state.slice(arrayIndex + 1),
          ];
          return updatedState;
        }
        return state;
      },
      removeAllProductAfterSale: (state) => {
        return [];
      },
    },
  });
  export const {
    addToSaleCartReducer,
    removeSaleProductFromState,
    removeAllProductAfterSale
  } = saleCartSlice.actions;
  export default saleCartSlice.reducer;