// cartSlice.js
import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
  name: "cart",
  initialState: [],
  reducers: {
    addToCartReducer: (state, action) => {
      state.push(action.payload);
    },
    removeProductFromState: (state, action) => {
      const arrayIndex = action.payload - 1;
      if (arrayIndex >= 0 && arrayIndex < state.length) {
        const updatedState = [
          ...state.slice(0, arrayIndex),
          ...state.slice(arrayIndex + 1),
        ];
        return updatedState;
      }
      return state;
    },
    removeAllProductAfterPurchase: (state) => {
      return [];
    },
  },
});

export const {
  addToCartReducer,
  removeProductFromState,
  removeAllProductAfterPurchase,
} = cartSlice.actions;
export default cartSlice.reducer;
