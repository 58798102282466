import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { privateGet, privatePost } from '../../../utils/apiCaller';

export const createEmployeeDesignation = createAsyncThunk(
    'createDesignation/createEmployeeDesignationHistory',
    async ({ token, data }, { rejectWithValue }) => {
        try {
            const response = await privatePost('/hrm/employee/designation', token, data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response);
        }
    }
);
export const fetchEmployeeDesignation = createAsyncThunk(
    'designationHistory/fetchEmployeeDesignationHistory',
    async (token, { rejectWithValue }) => {
        try {
            const response = await privateGet('/hrm/employee/designation/list', token);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response);
        }
    }
);
export const employeeDesignationSlice = createSlice({
    name: 'employeeDesignation',
    initialState: {
        designations: [],
        designation: [],
        isLoading: false,
        error: false,
        success: false,
        successMessage: '',
        designationAdded: false,
        errorMessage: '',
    },
    reducers: {
        resetEmployeeDesignationState: (state) => {
            state.designationAdded = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(createEmployeeDesignation.pending, (state) => {
                state.isLoading = true;
                state.error = false;
                state.success = false;
            })
            .addCase(createEmployeeDesignation.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = false;
                state.success = true;
                state.designationAdded = true;
                state.successMessage = action.payload.message;
                state.designation = action.payload;
            })
            .addCase(createEmployeeDesignation.rejected, (state, action) => {
                state.isLoading = false;
                state.error = true;
                state.success = false;
                state.errorMessage = action.payload.data.message || 'An error occurred.';
                state.designation = [];
            })
            .addCase(fetchEmployeeDesignation.pending, (state) => {
                state.isLoading = true;
                state.error = false;
                state.success = false;
            })
            .addCase(fetchEmployeeDesignation.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = false;
                state.success = true;
                state.successMessage = action.payload.message;
                state.designations = action.payload;
            })
            .addCase(fetchEmployeeDesignation.rejected, (state, action) => {
                state.isLoading = false;
                state.error = true;
                state.success = false;
                state.errorMessage = action.payload.data.message || 'An error occurred.';
                state.designations = [];
            })

    },
});
export const { resetEmployeeDesignationState } = employeeDesignationSlice.actions;

export default employeeDesignationSlice.reducer;