import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { privateGet } from "../../../utils/apiCaller";

export const fetchCategory = createAsyncThunk(
    "category/fetchCategory",
    async (token, { rejectWithValue }) => {
        try {
            const response = await privateGet("/product/categories", token);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response);
        }
    }
);

export const fetchCategorySlice = createSlice({
    name: "Category",
    initialState: {
        categories: [],
        isLoading: false,
        error: false,
        success: false,
        successMessage: "",
        errorMessage: "",
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCategory.pending, (state) => {
                state.isLoading = true;
                state.error = false;
                state.success = false;
            })
            .addCase(fetchCategory.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = false;
                state.success = true;
                state.successMessage = action.payload.message;
                state.categories = action.payload;
            })
            .addCase(fetchCategory.rejected, (state, action) => {
                state.isLoading = false;
                state.error = true;
                state.success = false;
                state.errorMessage =
                    action.payload.data.message || "An error occurred.";
                state.categories = [];
            });

    },
});
export default fetchCategorySlice.reducer;
