import { createSlice } from "@reduxjs/toolkit";

const customerStoreSlice = createSlice({
  name: "customer",
  initialState: { customer: null },
  reducers: {
    addCustomerToStoreReducer: (state, action) => {
      state.customer = action.payload;
    },
    removeAllCustomerAfterSale: (state) => {
      state.customer = null;
    },
  },
});

export const {
  addCustomerToStoreReducer,
  removeAllCustomerAfterSale
} = customerStoreSlice.actions;
export default customerStoreSlice.reducer;