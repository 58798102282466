import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { privateGet, privatePost, privatePut } from './../../../../utils/apiCaller';

export const fetchProducts = createAsyncThunk(
  'products/fetchProducts',
  async (token, { rejectWithValue }) => {
    try {
      const response = await privateGet('/product/get', token);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);
export const addProduct = createAsyncThunk(
  "products/addProduct",
  async ({ token, product }, { rejectWithValue }) => {
    try {
      const response = await privatePost("/product/purchase", token, product);
      return response;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);
export const fetchPurchaseInvoice = createAsyncThunk(
  "purchase/fetchPurchaseInvoice",
  async ( {token, limit, offset},{ rejectWithValue }) => {
    try {
      const response = await privateGet(`/product/purchase/invoice?limit=${limit}&offset=${offset}`,token);
      return response;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);
export const fetchSinglePurchaseInvoice = createAsyncThunk(
  "purchase/fetchSinglePurchaseInvoice",
  async ( {token,selectedInvoice},{ rejectWithValue }) => {
    try {
      const response = await privateGet(`/product/purchase/${selectedInvoice}`,token);
      console.log(response);
      return response;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const updateProduct = createAsyncThunk(
  "products/updateProduct",
  async ({ token, product }, { rejectWithValue }) => {
    try {
      const response = await privatePut(
        `/product/update/${product.id}`,
        token,
        product
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const addPurchaseProduct = createAsyncThunk(
  "products/addPurchaseProduct",
  async ({ token, product }, { rejectWithValue }) => {
    try {
      const response = await privatePost("/product/purchase", token, product);
      return response;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);
export const productsSlice = createSlice({
  name: "products",
  initialState: {
    products: [],
    invoices:[],
    invoice:[],
    isLoading: false,
    error: false,
    success: false,
    successMessage: "",
    productCreated: false,
    purchaseCreated: false,
    errorMessage: "",
    totalCount:0
  },
  reducers: {
    resetProductState: (state) => {
      state.isLoading = false;
      state.error = false;
      state.success = false;
      state.successMessage = '';
      state.productCreated = false;
      state.purchaseCreated = false;
      state.errorMessage = '';
      
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchProducts.pending, state => {
        state.isLoading = true;
        state.error = false;
        state.success = false;
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = false;
        state.success = true;
        state.successMessage = action.payload.message;
        state.products = action.payload;
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.success = false;
        state.errorMessage = action.payload.data.message || "An error occurred.";
        state.products = [];
      })
      .addCase(addProduct.pending, (state) => {
        state.isLoading = true;
        state.error = false;
        state.success = false;
      })
      .addCase(addProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = false;
        state.productCreated = true;
        state.successMessage = action.payload.message;
        state.success = true;
      })
      .addCase(addProduct.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.success = false;
        state.errorMessage = action.payload.data.message || 'An error occurred while adding a product.';
      })
      .addCase(addPurchaseProduct.pending, (state) => {
        state.isLoading = true;
        state.error = false;
        state.success = false;
      })
      .addCase(addPurchaseProduct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = false;
        state.purchaseCreated = true;
        state.successMessage = action.payload.message;
        state.success = true;
      })
      .addCase(addPurchaseProduct.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.success = false;
        state.errorMessage = action.payload.data.message || 'An error occurred while purchase products.';
      })
      .addCase(fetchPurchaseInvoice.pending, (state) => {
        state.isLoading = true;
        state.error = false;
        state.success = false;
      })
      .addCase(fetchPurchaseInvoice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = false;
        state.invoices = action.payload.data.purchases;
        state.totalCount=action.payload.data.totalCount;
        state.successMessage = action.payload.message;
        state.success = true;
      })
      .addCase(fetchPurchaseInvoice.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.success = false;
        state.errorMessage = action.payload.data.message || 'An error occurred while purchase products.';
      })
      .addCase(fetchSinglePurchaseInvoice.pending, (state) => {
        state.isLoading = true;
        state.error = false;
        state.success = false;
      })
      .addCase(fetchSinglePurchaseInvoice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = false;
        state.invoice = action.payload;
        state.successMessage = action.payload.message;
        state.success = true;
      })
      .addCase(fetchSinglePurchaseInvoice.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.success = false;
        state.errorMessage = action.payload.data.message || 'An error occurred while purchase products.';
      })
  },
});
export const { resetProductState } = productsSlice.actions;
export default productsSlice.reducer;
