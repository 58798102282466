import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {privateGet, privatePost } from '../../../utils/apiCaller';

export const createEmployeeDepartment = createAsyncThunk(
    'createDepartment/createEmployeeDepartmentHistory',
    async ({ token, data }, { rejectWithValue }) => {
        try {
            const response = await privatePost('/hrm/department', token, data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response);
        }
    }
);
export const fetchDepartment = createAsyncThunk(
    'departmentHistory/fetchDepartmentHistory',
    async (token, { rejectWithValue }) => {
        try {
            const response = await privateGet('/hrm/departments', token);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response);
        }
    }
);
export const employeeDepartmentSlice = createSlice({
    name: 'employeeDepartment',
    initialState: {
        departments: [],
        department: [],
        isLoading: false,
        error: false,
        success: false,
        successMessage: '',
        departmentAdded: false,
        errorMessage: '',
    },
    reducers: {
        resetEmployeeDepartmentState: (state) => {
            state.departmentAdded = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(createEmployeeDepartment.pending, (state) => {
                state.isLoading = true;
                state.error = false;
                state.success = false;
            })
            .addCase(createEmployeeDepartment.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = false;
                state.success = true;
                state.departmentAdded = true;
                state.successMessage = action.payload.message;
                state.department = action.payload;
            })
            .addCase(createEmployeeDepartment.rejected, (state, action) => {
                state.isLoading = false;
                state.error = true;
                state.success = false;
                state.errorMessage = action.payload.data.message || 'An error occurred.';
                state.department = [];
            })
            .addCase(fetchDepartment.pending, (state) => {
                state.isLoading = true;
                state.error = false;
                state.success = false;
            })
            .addCase(fetchDepartment.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = false;
                state.success = true;
                state.successMessage = action.payload.message;
                state.departments = action.payload;
            })
            .addCase(fetchDepartment.rejected, (state, action) => {
                state.isLoading = false;
                state.error = true;
                state.success = false;
                state.errorMessage = action.payload.data.message || 'An error occurred.';
                state.departments = [];
            })
    },
});
export const { resetEmployeeDepartmentState } = employeeDepartmentSlice.actions;

export default employeeDepartmentSlice.reducer;