import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { privateGet } from '../../../utils/apiCaller';

export const fetchAccountHistory = createAsyncThunk(
    'accountHistory/fetchAccountHistory',
    async (token, { rejectWithValue }) => {
      try {
        const response = await privateGet('/account/sale', token);
        return response.data;
      } catch (err) {
        return rejectWithValue(err.response);
      }
    }
  );
  
export const accountHistorySlice = createSlice({
    name: 'account',
    initialState: {
        history: [],
        isLoading: false,
        error: false,
        success: false,
        errorMessage: ""
    },
    extraReducers: (builder) => {
        builder
          .addCase(fetchAccountHistory.pending, (state) => {
            state.isLoading = true;
          })
          .addCase(fetchAccountHistory.fulfilled, (state, action) => {
            state.history = action.payload;
            state.isLoading = false;
            state.errorMessage = "";
            state.success = true;
          })
          .addCase(fetchAccountHistory.rejected, (state, action) => {
            state.isLoading = false;
            state.error = true;
            state.errorMessage = action.payload.data.message;
            state.history = [];
          });
      },
});

export default accountHistorySlice.reducer;