import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { api } from "../configs";

const baseQuery = fetchBaseQuery({
  baseUrl: api,
  prepareHeaders: async (headers, { getState }) => {
    const { token } = getState().shop.shop;
    if (token) {
      headers.set("token", token);
    }
    return headers;
  }
});

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: async (args, api, extraOptions) => {
    const result = await baseQuery(args, api, extraOptions);
    return result;
  },
  endpoints: () => ({})
});
