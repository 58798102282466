import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  privateGet,
  privatePost,
  privatePutFile,
  publicPost,
} from "../../utils/apiCaller";
export const createShopLogin = createAsyncThunk(
  "shop/login",
  async (data, { rejectWithValue }) => {
    try {
      const response = await publicPost("/auth/login", data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);
export const getShopDetails = createAsyncThunk(
  "shop/details",
  async (token, { rejectWithValue }) => {
    try {
      const response = await privateGet("/auth/shop-details", token);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);
export const updateShopProfile = createAsyncThunk(
  "shop/updateShopProfile",
  async ({ token, data }, { rejectWithValue }) => {
    try {
      const response = await privatePutFile("/auth/shop/update", token, data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);
export const updatePassword = createAsyncThunk(
  "shop/updatePassword",
  async ({ token, data }, { rejectWithValue }) => {
    try {
      const updatepassword = await privatePost(
        "/auth/change-password",
        token,
        data
      );
      return updatepassword;
    } catch (err) {
      return rejectWithValue(err.response.data.message);
    }
  }
);
const authSlice = createSlice({
  name: "shop",
  initialState: {
    isAuthenticated: false,
    tokenExpireTime: "",
    isLoading: false,
    shop: {},
    error: false,
    errorMessage: "",
    updatedShop: false,
    isPasswordUpdated: false,
  },
  reducers: {
    login: (state, action) => {
      state.isAuthenticated = true;
      state.shop = action.payload;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.isLoading = false;
      state.tokenExpireTime = "";
      state.shop = {};
      state.error = false;
      state.errorMessage = "";
    },
    errorClean: (state) => {
      state.error = false;
      state.errorMessage = "";
      state.updatedShop = false;
    },
    updatedPasswordClean: (state) => {
      state.error = false;
      state.errorMessage = "";
      state.isPasswordUpdated = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createShopLogin.pending, (state) => {
      state.isLoading = true;
      state.error = false;
    });
    builder.addCase(createShopLogin.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.isAuthenticated = true;
      state.shop = action.payload;
      state.tokenExpireTime = action.payload.tokenExpireTime;
      state.errorMessage = "";
    });
    builder.addCase(createShopLogin.rejected, (state, action) => {
      state.isLoading = false;
      state.error = true;
      state.errorMessage = action.payload.data.message;
    });
    builder.addCase(getShopDetails.pending, (state) => {
      state.isLoading = true;
      state.error = false;
    });
    builder.addCase(getShopDetails.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.isAuthenticated = true;
      state.shop = action.payload;
      state.errorMessage = "";
    });
    builder.addCase(getShopDetails.rejected, (state, action) => {
      state.isLoading = false;
      state.error = true;
      state.errorMessage = action.payload.data.message;
    });
    builder.addCase(updateShopProfile.pending, (state) => {
      state.isLoading = true;
      state.error = false;
    });
    builder.addCase(updateShopProfile.fulfilled, (state, action) => {
      const { shop: previousShop } = state;
      state.isLoading = false;
      state.error = null;
      state.updatedShop = true;
      state.shop = {
        token: previousShop.token,
        isSubscribed: previousShop.isSubscribed,
        ...action.payload,
      };
      state.errorMessage = "";
    });
    builder.addCase(updateShopProfile.rejected, (state, action) => {
      state.isLoading = false;
      state.error = true;
      // state.errorMessage = action.payload.data.message;
    });
    builder.addCase(updatePassword.pending, (state, action) => {
      state.isLoading = true;
      state.error = action.payload;
    });
    builder.addCase(updatePassword.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = false;
      state.isPasswordUpdated = true;
    });
    builder.addCase(updatePassword.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      // state.error = action.error?.message
    });
  },
});

export const { login, logout, errorClean, updatedPasswordClean } =
  authSlice.actions;
export default authSlice.reducer;
