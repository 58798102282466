import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { privateGet, privatePost } from "../../../../utils/apiCaller";

// Async thunk to fetch sale product history data
export const fetchSaleProductHistory = createAsyncThunk(
  "saleProductHistory/fetchSaleProductHistory",
  async ({ token, limit, offset }, { rejectWithValue }) => {
    try {
      const response = await privateGet(
        `/product/sale/history?limit=${limit}&offset=${offset}`,
        token
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

// Async thunk to add sale product history
export const addSaleProductHistory = createAsyncThunk(
  "saleProductHistory/addSaleProductHistory",
  async ({ token, data }, { rejectWithValue }) => {
    try {
      const response = await privatePost("/product/sale", token, data);
      return response;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const fetchSaleInvoice = createAsyncThunk(
  "sales/fetchSaleInvoice",
  async ({ token, limit, offset }, { rejectWithValue }) => {
    try {
      const response = await privateGet(
        `/product/sale-invoices?limit=${limit}&offset=${offset}`,
        token
      );
      return response;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);
export const fetchSingleSaleInvoice = createAsyncThunk(
  "sales/fetchSingleInvoice",
  async ({ token, selectedInvoice }, { rejectWithValue }) => {
    try {
      const response = await privateGet(
        `/product/sale/${selectedInvoice}`,
        token
      );
      console.log(response);
      return response;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const saleProductHistorySlice = createSlice({
  name: "saleProductHistory",
  initialState: {
    saleProductHistory: [],
    saleInvoices: [],
    saleInvoice: [],
    isLoading: false,
    totalCount: 0,
    error: false,
    success: false,
    successMessage: "",
    saleProductAdded: false,
    errorMessage: "",
  },
  reducers: {
    resetSaleProductHistoryState: (state) => {
      state.isLoading = false;
      state.error = false;
      state.success = false;
      state.successMessage = "";
      state.saleProductAdded = false;
      state.errorMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSaleProductHistory.pending, (state) => {
        state.isLoading = true;
        state.error = false;
        state.success = false;
      })
      .addCase(fetchSaleProductHistory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = false;
        state.success = true;
        state.successMessage = action.payload.message;
        state.saleProductHistory = action.payload.saleProducts;
        state.totalCount = action.payload.totalCount;
      })
      .addCase(fetchSaleProductHistory.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.success = false;
        state.errorMessage =
          action.payload.data.message || "An error occurred.";
        state.saleProductHistory = [];
        state.totalCount = 0;
      })
      .addCase(addSaleProductHistory.pending, (state) => {
        state.isLoading = true;
        state.error = false;
        state.success = false;
      })
      .addCase(addSaleProductHistory.fulfilled, (state, action) => {
        console.log(action.payload.message);
        state.isLoading = false;
        state.error = false;
        state.saleProductAdded = true;
        state.successMessage = action.payload.message;
        state.success = true;
      })
      .addCase(addSaleProductHistory.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.success = false;
        state.errorMessage =
          action.payload.data.message ||
          "An error occurred while adding sale product history.";
      })
      .addCase(fetchSaleInvoice.pending, (state) => {
        state.isLoading = true;
        state.error = false;
        state.success = false;
      })
      .addCase(fetchSaleInvoice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = false;
        state.saleInvoices = action.payload.data.invoices;
        state.totalCount = action.payload.data.totalCount;
        state.successMessage = action.payload.message;
        state.success = true;
      })
      .addCase(fetchSaleInvoice.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.success = false;
        state.errorMessage =
          action.payload.data.message ||
          "An error occurred while purchase products.";
      })
      .addCase(fetchSingleSaleInvoice.pending, (state) => {
        state.isLoading = true;
        state.error = false;
        state.success = false;
      })
      .addCase(fetchSingleSaleInvoice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = false;
        state.saleInvoice = action.payload;
        state.successMessage = action.payload.message;
        state.success = true;
      })
      .addCase(fetchSingleSaleInvoice.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.success = false;
        state.errorMessage =
          action.payload.data.message ||
          "An error occurred while fetch invoice details.";
      });
  },
});

export const { resetSaleProductHistoryState } = saleProductHistorySlice.actions;

export default saleProductHistorySlice.reducer;
