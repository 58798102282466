import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import SuspenseContent from "./containers/SuspenseContent";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { store } from "./state/store";
import ThemeComponent from "./theme/ThemeComponent";
import { SettingsConsumer, SettingsProvider } from "./context/settingsContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Suspense fallback={<SuspenseContent />}>
      <Provider store={store}>
        <SettingsProvider>
          <SettingsConsumer>
            {({ settings }) => {
              return (
                <ThemeComponent settings={settings}>
                  <App />
                </ThemeComponent>
              );
            }}
          </SettingsConsumer>
        </SettingsProvider>
      </Provider>
    </Suspense>
  </React.StrictMode>
);
reportWebVitals();
