import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { privateGet, privatePost } from "../../../utils/apiCaller";

export const initiateSalary = createAsyncThunk(
  'hrm/initiateSalary',
  async ({token, data}, {rejectWithValue}) => {
    try {
      const response = await privatePost('/hrm/employee/salary', token, data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);
export const fetchEmployeeSalaryList = createAsyncThunk(
  'hrm/fetchSalaryList',
  async ({token, limit, offset }, {rejectWithValue}) => {
    try {
      const response = await privateGet(`/hrm/employee/salary/list?limit=${limit}&offset=${offset}`, token);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);
export const initiateSalarySlice = createSlice({
  name: 'initiateSalary',
  initialState: {
    employeeSalaryList: [],
    totalCount: 0,
    isLoading: false,
    error: false,
    success: false,
    successMessage: "",
    isSalaryGiven: false,
    errorMessage: "",
  },
  reducers: {
    resetGiveSalary: (state) => {
      state.isSalaryGiven = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(initiateSalary.pending, (state) => {
        state.isLoading = true;
        state.error = false;
        state.success = false;
      })
      .addCase(initiateSalary.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = false;
        state.success = true;
        state.isSalaryGiven = true;
        state.successMessage = action.payload.message;
      })
      .addCase(initiateSalary.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.success = false;
        state.errorMessage = action.payload.data.message || 'An Error Occoured.';
      })
      .addCase(fetchEmployeeSalaryList.pending, (state) => {
        state.isLoading = true;
        state.error = false;
        state.success = false;
      })
      .addCase(fetchEmployeeSalaryList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = false;
        state.success = true;
        state.successMessage = action.payload.message;
        state.employeeSalaryList = action.payload.salaryList;
        state.totalCount = action.payload.totalCount;
      })
      .addCase(fetchEmployeeSalaryList.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.success = false;
        state.errorMessage = action.payload.data.message || "An error Occured";
        state.employeeSalaryList = [];
        state.totalCount = 0
      })
  }
});
export const {resetGiveSalary} = initiateSalarySlice.actions;
export default initiateSalarySlice.reducer;