import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { privateGet, privatePost } from "../../../utils/apiCaller";

export const fetchCustomer = createAsyncThunk(
    "customer/fetchCustomer",
    async (token, { rejectWithValue }) => {
        try {
            const response = await privateGet("/admin/get-customers", token);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response);
        }
    }
);
export const addCustomer = createAsyncThunk(
    "customer/addCustomer",
    async ({ token, updatedFormData }, { rejectWithValue }) => {
        try {
            const response = await privatePost(
                "/admin/add-customer",
                token,
                updatedFormData
            );
            return response;
        } catch (err) {
            return rejectWithValue(err.response);
        }
    }
);

export const customerSlice = createSlice({
    name: "Customer",
    initialState: {
        customers: [],
        isLoading: false,
        error: false,
        success: false,
        successMessage: "",
        customerCreated: false,
        errorMessage: "",
    },
    reducers: {
        resetCustomerState: (state) => {
            state.isLoading = false;
            state.error = false;
            state.success = false;
            state.successMessage = "";
            state.customerCreated = false;
            state.errorMessage = "";
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCustomer.pending, (state) => {
                state.isLoading = true;
                state.error = false;
                state.success = false;
            })
            .addCase(fetchCustomer.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = false;
                state.success = true;
                state.successMessage = action.payload.message;
                state.customers = action.payload;
            })
            .addCase(fetchCustomer.rejected, (state, action) => {
                state.isLoading = false;
                state.error = true;
                state.success = false;
                state.errorMessage =
                    action.payload.data.message || "An error occurred.";
                state.customers = [];
            })
            .addCase(addCustomer.pending, (state) => {
                state.isLoading = true;
                state.error = false;
                state.success = false;
            })
            .addCase(addCustomer.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = false;
                state.customerCreated = true;
                state.successMessage = action.payload.message;
                state.success = true;
            })
            .addCase(addCustomer.rejected, (state, action) => {
                state.isLoading = false;
                state.error = true;
                state.success = false;
                state.errorMessage =
                    action.payload.data.message ||
                    "An error occurred while adding a Customer.";
            });
    },
});
export const { resetCustomerState } = customerSlice.actions;
export default customerSlice.reducer;
