import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { publicGet } from '../../../utils/apiCaller';


export const fetchPlans = createAsyncThunk('subscriptionPlan/fetchPlans', async (_, { rejectWithValue }) => {
    try {
      const response = await publicGet('/plan/get');
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  });
  
export const subscriptionPlanSlice = createSlice({
    name: 'plan',
    initialState: {
        plans: [],
        isLoading: false,
        error: false,
        success: false,
        errorMessage: ""
    },
    extraReducers: (builder) => {
        builder
          .addCase(fetchPlans.pending, (state) => {
            state.isLoading = true;
          })
          .addCase(fetchPlans.fulfilled, (state, action) => {
            state.plans = action.payload;
            state.isLoading = false;
            state.errorMessage = "";
            state.success = true;
          })
          .addCase(fetchPlans.rejected, (state, action) => {
            state.isLoading = false;
            state.error = true;
            state.errorMessage = action.payload.data.message;
            state.plans = [];
          });
      },
});

export default subscriptionPlanSlice.reducer;