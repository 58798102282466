import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { privateGet } from "../../../utils/apiCaller";

export const fetchCurrentSubscriptionHistory = createAsyncThunk(
  "currentsubscriptions/fetchCurrentSubscriptionHistory",
  async (token, { rejectWithValue }) => {
    try {
      const response = await privateGet("/subscription/active-details", token);
      return response;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const subscriptionsSlice = createSlice({
  name: "subscriptions",
  initialState: {
    subscription: [],
    isLoading: false,
    error: false,
    success: false,
    successMessage: "",
    errorMessage: "",
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchCurrentSubscriptionHistory.pending, (state) => {
        state.isLoading = true;
        state.error = false;
        state.success = false;
      })
      .addCase(fetchCurrentSubscriptionHistory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = false;
        state.subscription = action.payload.data;
        state.successMessage = action.payload.message;
        state.success = true;
      })
      .addCase(fetchCurrentSubscriptionHistory.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.success = false;
        state.errorMessage =
          action.payload.data.message ||
          "An error occurred while fetch subscriptions.";
      });
  },
});
export default subscriptionsSlice.reducer;
