import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { privateGet, privatePost } from "../../../utils/apiCaller";

export const fetchSupplier = createAsyncThunk(
  "supplier/fetchSupplier",
  async (token, { rejectWithValue }) => {
    try {
      const response = await privateGet("/admin/get-suppliers", token);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);
export const addSupplier = createAsyncThunk(
  "supplier/addSupplier",
  async ({ token, updatedFormData }, { rejectWithValue }) => {
    try {
      const response = await privatePost(
        "/admin/add-supplier",
        token,
        updatedFormData
      );
      return response;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const supplierSlice = createSlice({
  name: "supplier",
  initialState: {
    suppliers: [],
    isLoading: false,
    error: false,
    success: false,
    successMessage: "",
    supplierCreated: false,
    errorMessage: "",
  },
  reducers: {
    resetSupplierState: (state) => {
      state.isLoading = false;
      state.error = false;
      state.success = false;
      state.successMessage = "";
      state.supplierCreated = false;
      state.errorMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSupplier.pending, (state) => {
        state.isLoading = true;
        state.error = false;
        state.success = false;
      })
      .addCase(fetchSupplier.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = false;
        state.success = true;
        state.successMessage = action.payload.message;
        state.suppliers = action.payload;
      })
      .addCase(fetchSupplier.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.success = false;
        state.errorMessage =
          action.payload.data.message || "An error occurred.";
        state.suppliers = [];
      })
      .addCase(addSupplier.pending, (state) => {
        state.isLoading = true;
        state.error = false;
        state.success = false;
      })
      .addCase(addSupplier.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = false;
        state.supplierCreated = true;
        state.successMessage = action.payload.message;
        state.success = true;
      })
      .addCase(addSupplier.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.success = false;
        state.errorMessage =
          action.payload.data.message ||
          "An error occurred while adding a supplier.";
      });
  },
});
export const { resetSupplierState } = supplierSlice.actions;
export default supplierSlice.reducer;
