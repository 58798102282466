import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { publicPost } from "../../utils/apiCaller";
export const createShopRegister = createAsyncThunk(
  "shop/register",
  async (data, { rejectWithValue }) => {
    try {
      const response = await publicPost("/auth/register", data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

const registerSlice = createSlice({
  name: "register",
  initialState: {
    isLoading: false,
    register: {},
    error: false,
    success: false,
    errorMessage: ""
  },
  reducers: {
    registrationClean: state => {
      state.error = false;
      state.errorMessage = "";
      state.success = false;
    }
  },
  extraReducers: builder => {
    builder.addCase(createShopRegister.pending, state => {
      state.isLoading = true;
      state.error = false;
    });
    builder.addCase(createShopRegister.fulfilled, (state, action) => {
      state.isLoading = false;
      state.error = null;
      state.register = action.payload;
      state.errorMessage = "";
      state.success = true
    });
    builder.addCase(createShopRegister.rejected, (state, action) => {
      state.isLoading = false;
      state.error = true;
      state.errorMessage = action.payload.data.message;
    });
  }
});
export const { registrationClean } = registerSlice.actions;
export default registerSlice.reducer;