import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { privateGet } from "../../../utils/apiCaller";

export const fetchSubscriptionList = createAsyncThunk(
  "subscriptions/fetchSubscriptionList",
  async ({ token, limit, offset }, { rejectWithValue }) => {
    try {
      const response = await privateGet(
        `/subscription/list?limit=${limit}&offset=${offset}`,
        token
      );
      return response;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const subscriptionListSlice = createSlice({
  name: "subscriptionList",
  initialState: {
    subscriptionList: [],
    totalCount: 0,
    isLoading: false,
    error: false,
    success: false,
    successMessage: "",
    errorMessage: "",
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchSubscriptionList.pending, (state) => {
        state.isLoading = true;
        state.error = false;
        state.success = false;
      })
      .addCase(fetchSubscriptionList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = false;
        state.subscriptionList = action.payload.data.subscriptionList;
        state.successMessage = action.payload.message;
        state.totalCount = action.payload.data.totalCount;
        state.success = true;
      })
      .addCase(fetchSubscriptionList.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.success = false;
        state.totalCount = 0;
        state.errorMessage =
          action.payload.data.message ||
          "An error occurred while fetch subscriptionList.";
      });
  },
});
export default subscriptionListSlice.reducer;
