import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { privateGet } from "./../../../../utils/apiCaller";

export const fetchProductCountByCategory = createAsyncThunk(
  "products/fetchProductCountByCategory",
  async (token, { rejectWithValue }) => {
    try {
      const response = await privateGet("/product/sale-category", token);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const productsByCategorySlice = createSlice({
  name: "productCountByCategory",
  initialState: {
    productCountByCategory: [],
    isLoading: false,
    error: false,
    success: false,
    errorMessage: "",
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchProductCountByCategory.pending, (state) => {
        state.isLoading = true;
        state.error = false;
        state.success = false;
      })
      .addCase(fetchProductCountByCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = false;
        state.success = true;
        state.productCountByCategory = action.payload;
      })
      .addCase(fetchProductCountByCategory.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.success = false;
        state.errorMessage =
          action.payload.data.message ||
          "An error occurred while fetching product count by category.";
        state.productCountByCategory = [];
      });
  },
});

export default productsByCategorySlice.reducer;