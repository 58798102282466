import { combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
// import storage from 'localforage';
import headerSlice from "../features/common/headerSlice";
import modalSlice from "../features/common/modalSlice";
import rightDrawerSlice from "../features/common/rightDrawerSlice";
import shop from "./reducers/authSlice";
import registerSlice from "./reducers/registerSlice";
import subscriptionPlanSlice from "./reducers/plan/planSlice";
import { apiSlice } from "./apiSlice";
import productsSlice from "./reducers/products/product/productSlice";
import saleProductHistorySlice from "./reducers/products/saleProduct/saleProductSlice";
import accountHistorySlice from "./reducers/account/accountSlice";
import productsByCategorySlice from "./reducers/products/category/categoryByProductSlice";
import cartReducer from "./reducers/cart/cartSlice";
import saleCartReducer from "./reducers/cart/saleCartSlice";
import customerStoreReducer from "./reducers/customer/customerStoreSlice";
import supplierSlice from "./reducers/suppliers/supplierSlice";
import customerSlice from "./reducers/customer/customerSlice";
import fetchCategorySlice from "./reducers/category/categorySlice";
import employeeDepartmentSlice from "./reducers/hrm/departmentSlice";
import createEmployeeSlice from "./reducers/hrm/employeeSlice";
import employeeDesignationSlice from "./reducers/hrm/designationSlice";
import initiateSalarySlice from "./reducers/hrm/employeeSalarySlice";
import subscriptionsSlice from "./reducers/subscription/subscriptionsSlice";
import subscriptionHistorySlice from "./reducers/subscription/subscriptionHistorySlice";
import subscriptionListSlice from "./reducers/subscription/subscriptionListSlice";
const appReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  header: headerSlice,
  rightDrawer: rightDrawerSlice,
  modal: modalSlice,
  shop: shop,
  register: registerSlice,
  plan: subscriptionPlanSlice,
  product: productsSlice,
  saleProduct: saleProductHistorySlice,
  history: accountHistorySlice,
  productsCount: productsByCategorySlice,
  cart: cartReducer,
  saleCart: saleCartReducer,
  customerStore: customerStoreReducer,
  supplier: supplierSlice,
  customer: customerSlice,
  category: fetchCategorySlice,
  departments: employeeDepartmentSlice,
  employees: createEmployeeSlice,
  designations: employeeDesignationSlice,
  salary: initiateSalarySlice,
  subscriptions: subscriptionsSlice,
  subscriptionHistory: subscriptionHistorySlice,
  subscriptionList: subscriptionListSlice,
});

const rootReducer = (state, action) => {
  if (action.type === "shop/logout") {
    state = {};
    storage.removeItem("persist:shop");
  }

  return appReducer(state, action);
};

export default rootReducer;
