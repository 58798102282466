import React, { lazy } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import { TokenChecker } from "./components/Auth/TokenChecker";
// Importing pages
const Layout = lazy(() => import("./containers/Layout"));
const Login = lazy(() => import("./pages/Login"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const Register = lazy(() => import("./pages/Register"));
const Premium = lazy(() => import("./pages/protected/Premium"));

// Initializing different libraries
// initializeApp();

function App() {
  const { shop } = useSelector((state) => state.shop);
  const { isSubscribed, token } = shop;

  const PUBLIC_ROUTES = [
    "login",
    "forgot-password",
    "register",
    "documentation",
  ];
  const isPublicPage = PUBLIC_ROUTES.some((r) =>
    window.location.href.includes(r)
  );
  if (!token && !isPublicPage) {
    window.location.href = "/login";
  }
  return (
    <>
      <Router>
        <Toaster />
        <Routes>
          {token ? (
            isSubscribed ? (
              <Route
                path="/app/*"
                element={
                  <TokenChecker>
                    <Layout />
                  </TokenChecker>
                }
              />
            ) : (
              <Route path="/premium" element={<Premium />} />
            )
          ) : (
            <>
              <Route path="/login" element={<Login />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/register" element={<Register />} />
            </>
          )}
          <Route
            path="*"
            element={
              <Navigate
                to={
                  token
                    ? isSubscribed
                      ? "/app/dashboard"
                      : "/premium"
                    : "/login"
                }
                replace
              />
            }
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
