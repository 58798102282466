import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import createExpirationTransform from "redux-persist-transform-expire";
import storage from "redux-persist/lib/storage";
// import headerSlice from "../features/common/headerSlice";
// import modalSlice from "../features/common/modalSlice";
// import rightDrawerSlice from "../features/common/rightDrawerSlice";
// import authSlice from "./reducers/authSlice";
// import registerSlice from "./reducers/registerSlice";
// import subscriptionPlanSlice from "./reducers/plan/planSlice";
import { apiSlice } from "./apiSlice";
// import productsSlice from "./reducers/products/product/productSlice";
// import saleProductHistorySlice from "./reducers/products/saleProduct/saleProductSlice";
// import accountHistorySlice from "./reducers/account/accountSlice";
// import productsByCategorySlice from "./reducers/products/category/categoryByProductSlice";
import rootReducer from "./reducers";
const expireTransform = createExpirationTransform({
  expireKey: "expiresAt",
});
const persistConfig = {
  key: "authentication",
  storage,
  whitelist: ["shop"],
  transforms: [expireTransform],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
// const combinedReducer = {
//   [apiSlice.reducerPath]: apiSlice.reducer,
//   header: headerSlice,
//   rightDrawer: rightDrawerSlice,
//   modal: modalSlice,
//   shop: persistedReducer,
//   register: registerSlice,
//   plan: subscriptionPlanSlice,
//   product: productsSlice,
//   saleProduct: saleProductHistorySlice,
//   history: accountHistorySlice,
//   productsCount: productsByCategorySlice,
// };
const middlewares = [];
middlewares.push(apiSlice.middleware);

if (process.env.NODE_ENV === "development") {
  const { logger } = require("redux-logger");
  middlewares.push(logger);
}
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(middlewares),
  devTools: true,
});
export const persistor = persistStore(store);
