import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { logout } from "../../state/reducers/authSlice";

const checkIfTokenExpired = ({ tokenExpireTime, dispatch, navigate }) => {
  const currentDate = new Date();
  const timestampInSeconds = Math.floor(currentDate.getTime() / 1000);
  if (tokenExpireTime <= timestampInSeconds) {
    dispatch(logout());
    window.location.reload();
    // navigate("/");
  }
};

export const TokenChecker = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { tokenExpireTime } = useSelector((state) => state.shop);
  useEffect(() => {
    checkIfTokenExpired({ tokenExpireTime, dispatch, navigate });
  }, [dispatch, navigate, tokenExpireTime]);

  useEffect(() => {
    checkIfTokenExpired({ tokenExpireTime, dispatch, navigate });
  }, [location.pathname, dispatch, navigate, tokenExpireTime]);

  return <div>{children}</div>;
};
