import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { privateGet, privatePost } from "../../../utils/apiCaller";

export const createEmployee = createAsyncThunk(
  "hrm/createEmployee",
  async ({ token, data }, { rejectWithValue }) => {
    try {
      const response = await privatePost("/hrm/employee", token, data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);
export const fetchEmployeeList = createAsyncThunk(
  "hrm/fetchEmployee",
  async ({ token, limit, offset }, { rejectWithValue }) => {
    try {
      const response = await privateGet(
        `/hrm/employees?limit=${limit}&offset=${offset}`,
        token
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response);
    }
  }
);

export const createEmployeeSlice = createSlice({
  name: "createEmployee",
  initialState: {
    employee: [],
    employees: [],
    isLoading: false,
    error: false,
    success: false,
    successMessage: "",
    employeeAdded: false,
    errorMessage: "",
    totalCount: 0,
  },
  reducers: {
    resetCreateEmployee: (state) => {
      state.employeeAdded = false;
      state.errorMessage = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createEmployee.pending, (state) => {
        state.isLoading = true;
        state.error = false;
        state.success = false;
      })
      .addCase(createEmployee.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = false;
        state.success = true;
        state.employeeAdded = true;
        state.successMessage = action.payload.message;
        state.employee = action.payload;
      })
      .addCase(createEmployee.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.success = false;
        state.errorMessage =
          action.payload.data.message || "An error occurred.";
        state.employee = [];
      })
      .addCase(fetchEmployeeList.pending, (state) => {
        state.isLoading = true;
        state.error = false;
        state.success = false;
      })
      .addCase(fetchEmployeeList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = false;
        state.success = true;
        state.successMessage = action.payload.message;
        state.employees = action.payload.employees;
        state.totalCount = action.payload.totalCount;
      })
      .addCase(fetchEmployeeList.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
        state.success = false;
        state.errorMessage =
          action.payload.data.message || "An error occurred.";
        state.employees = [];
      });
  },
});
export const { resetCreateEmployee } = createEmployeeSlice.actions;

export default createEmployeeSlice.reducer;
